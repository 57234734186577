<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        :sort-by="title"
        class="elevation-1"
      >
        <template v-slot:[`header.nombre`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`item.logo`]="{ item }">
          <v-img
            contain
            max-height="60"
            max-width="60"
            :src="item.logo ? getLogo(item) : null"
            alt=""
          ></v-img>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <div :class="$getCss(item.activo, 'activo')" dark>
            {{ $getValue(item.activo, "activo") }}
          </div>
        </template>

        <template v-slot:top>
          <v-row>
            <v-col sm="6" class="pt-20">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="'Busqueda de ' + title"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col sm="2"></v-col>
            <v-col sm="3" class="">
              <v-autocomplete
                v-model="selectedHeaders"
                :items="headers"
                label="Columnas Visibles"
                multiple
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 2">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 2" class="grey--text caption"
                    >(otras {{ selectedHeaders.length - 2 }}+)</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="1">
              <v-dialog
                :key="keyDialog"
                v-model="dialog"
                max-width="50%"
                persistent
              >
                <template v-if="visible" v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="$cv('principal')"
                    dark
                    class="mx-2"
                    fab
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon blue>mdi-plus</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-toolbar dark :color="$cv('principal')">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items v-if="visible">
                        <v-btn dark text @click="saveMarca()" :disabled="!valid"
                          >Guardar</v-btn
                        >
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              v-model="editedItem.nombre"
                              :label="title"
                              :readonly="!visible ? true : false"
                              :rules="[$rulesRequerido]"
                              maxlength="255"
                              counter="255"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8" sm="8">
                            <v-file-input
                              label="Logo"
                              v-model="editedItem.logo"
                              accept="image/*"
                              prepend-icon="mdi-camera"
                              :readonly="!visible ? true : false"
                              :rules="[$rulesImg]"
                              @change="selectFile"
                            ></v-file-input>
                          </v-col>
                          <v-col md="4" sm="4">
                            <v-img
                              class="mx-auto"
                              contain
                              max-height="80"
                              max-width="80"
                              :src="logoImg"
                              alt=""
                            ></v-img>
                          </v-col>
                        </v-row>
                        <!--
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-row>
                            <v-col cols="12" sm="2"
                              ><label for="one">Activo:</label>
                            </v-col>
                            <v-col cols="12" sm="2"
                              ><input
                                type="radio"
                                value="1"
                                v-model="editedItem.activo"
                              />
                              Si</v-col
                            >
                            <v-col cols="12" sm="2"
                              ><input
                                type="radio"
                                value="0"
                                v-model="editedItem.activo"
                              />
                              No</v-col
                            >
                          </v-row>
                          
                        </v-col>
                      </v-row>
                      -->
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="visible"
            small
            class="mr-2"
            :color="$cv('btnEditar')"
            title="Editar"
            @click="editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-else
            class="mr-2"
            :color="$cv('btnVista')"
            title="Ver"
            @click="editItem(item.id)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-if="visible"
            small
            :color="$cv('btnEliminar')"
            title="Eliminar"
            @click="$deleteItem(item.id, item.nombre)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="visible && item.activo == 0"
            small
            :color="$cv('btnActivo')"
            :title="$cv('titleActivar')"
            @click="$enabledItem(item, 1)"
          >
            mdi-archive-arrow-up-outline
          </v-icon>
          <v-icon
            v-else-if="visible"
            small
            :color="$cv('btnInactivo')"
            :title="$cv('titleInactivo')"
            @click="$enabledItem(item, 0)"
          >
            mdi-archive-arrow-down
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Marca";
}

export default {
  data: (vm) => ({
    keyDialog: 0,
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    title: title(),
    dessertNombre: "",
    route: "marcas",
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    search: "",
    logoImg: "",
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: title(), filterable: true, value: "nombre" },

      { text: "Logo", value: "logo" },
      { text: "Creado", value: "created_at" },
      { text: "Actualizado", value: "updated_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: { file: {} },
    defaultItem: {},
    desserts: [],
    filters: {
      nombre: "",
    },
    filterKey: [],
    selectedHeaders: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : (this.visible ? "Editar " : "Ver ") + this.title;
    },
    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    filteredDesserts() {
      let conditions = [];
      if (this.dessertNombre) {
        conditions.push(this.filterDessertNombre);
      }
      if (this.dessertEstado) {
        conditions.push(this.filterDessertEstado);
      }
      if (conditions.length > 0) {
        return this.desserts.filter((dessert) => {
          return conditions.every((condition) => {
            return condition(dessert);
          });
        });
      }

      return this.desserts;
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$close();
        this.logoImg = "";
      }
    },
  },

  created() {
    this.$initialize();
    this.selectedHeaders = this.headers;
    this.isVisible();
  },

  methods: {
    editItem(id) {
      let vm = this;
      vm.$alertGetInfo("Obteniendo información <br><b></b>");
      /*vm.snackbar = true;
            vm.text = "Obteniendo datos ";
            vm.color = "primary";
            */
      vm.$axiosApi
        .getById(vm.route, id)
        .then((r) => {
          this.logoImg = vm.getLogo(r.data.data);
          vm.$respuesta(vm, r, 3);

          Swal.close();
        })
        .catch(function (error) {
          vm.$respuesta(vm, error, 3, true);
        });
    },
    async saveMarca() {
      let vm = this;

      if (vm.$refs.form.validate()) {
        vm.editedItem.file = this.editedItem.logo.name;
        if (vm.editedIndex > -1) {
          vm.$alertGetInfo("Actualizando información");

          await vm.$axiosApi
            .put(vm.route, vm.editedIndex, vm.editedItem)
            .then((r) => {
              this.upload();
              vm.$respuesta(vm, r, 2);
            })
            .catch((e) => {
              vm.$respuesta(vm, e, 2, true);
            });
        } else {
          vm.$alertGetInfo("Registrando información");

          await vm.$axiosApi
            .post(vm.route, vm.editedItem)
            .then((r) => {
              console.log(r.data.data);
              vm.editedIndex = r.data.data.id;
              this.upload();
              vm.$respuesta(vm, r, 1);
            })
            .catch((e) => {
              vm.$respuesta(e, 1, true);
            });
        }
        Swal.close();
        vm.$close();
      }
    },

    upload() {
      if (typeof this.editedItem.logo == "object") {
        var fd = new FormData();
        var file = this.editedItem.logo;
        console.log(this.editedIndex);
        fd.append("marca", this.editedIndex);
        let i = 0;
        if (file) {
          fd.append("file[" + i + "]", file, file.name);
          i++;
        }

        this.$axiosApi
          .postFile("marcas_upload", fd)
          .then((r) => {
            this.editedItem.logo = file.name;
            /*let newObject = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type,
          };*/

            //console.log(JSON.stringify(newObject));
            //this.editedItem.file = JSON.stringify(newObject);
            //subida exitosa
            console.log("upload ok");
            //this.$save();
            return true;
          })
          .catch((e) => {
            this.snackbar = true;
            this.text = "Error al cargar archivos. Error: " + e.message;
            this.color = "error";
          });
      } else {
        this.$save();
      }
    },

    getLogo(item) {
      if (item.file) {
        return (
          process.env.VUE_APP_API_URL +
          "/uploads/marcas/" +
          item.id +
          "/logo/" +
          item.file
        );
      }
    },
    selectFile(file) {
      this.editedItem.logo = file;
      if (file === null) {
        this.logoImg = "";
        return;
      }
      this.logoImg = URL.createObjectURL(file);
    },
    isVisible() {
      //this.visible = this.$keycloak.hasResourceRole("administrador")
      this.visible = this.is("administrador") ? true : false;
    },
    filterDessertNombre(item) {
      return item.nombre
        .toLowerCase()
        .includes(this.dessertNombre.toLowerCase());
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>
